.inner_box {
  width: 100%;
}
.card {
  margin: 0.8rem;
}
.input {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.options {
  display: flex;
  flex-direction: column;
}
.option {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #00b0f0;
}
.title {
  margin-right: 20vw;
  white-space: nowrap;
  font-weight: bold;
  font-size: 0.9rem;
  width: 4vw;
}

.text > span:nth-child(2) {
  font-size: 12px;
}
